.app-header.navbar {
  background-color: $green;
  color: $white;

  a, button {
    color: $white;
  }

  .navbar-nav {
    .dropdown {
      width: 100%;
      text-align: right;

      button {
        color: $dark;
        cursor: pointer;
      }
    }
  }
}
