form.with-labels .input-group {
  flex-direction: column;

  & > *, & > .form-control {
    width: auto;
  }

  label {
    margin: -.5em .5em .25em;
    font-size: .85em;
  }
}