nav.subnav {
  background: #FFFFFF;
  color: #55595C;
  box-shadow: 1px 0px 30px rgba(0, 0, 0, 0.1);

  ul.nav {
    font-size: 1rem;
    letter-spacing: 0.2px;
    font-weight: 400;
    overflow-y: hidden;
    padding-bottom: 0;

    & > li {
      display: block;
      width: 100%;

      &:hover > a {
        color: #3d3f41;
        background-color: whitesmoke;
      }

      & > a {
        padding: 8px 20px;
        outline: none;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #55595c;
        transition: all 0.2s ease;
  
        &.active {
          background: #f0f0f0;
          padding: 8px 20px;
          color: #55595c;
          font-weight: 700;
          border-right: 4px solid #3BAFDA;
        }
  
        i {
          margin-right: 12px;
          position: relative;
          top: 1px;
          transition: all 0.2s ease;
        }
      }
    }
  }
}