.app-body {
  .navbar {
    background: $white;
    margin-bottom: 1rem;
    padding: 0 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  
    .nav {
      .nav-link {
        padding: 1.25rem 0.75rem;
        
        &.active {
          background: whitesmoke;
        }
  
        .nav-icon {
          margin-right: 0.25rem;
        }
      }
    }
  }
}
