.content-list {
  &.card-columns {
    column-count: 2;
  }
}

.btn {
  & + .content-list,
  & + .content-table {
    margin-top: 1em;
  }
}